import React, { useEffect, useState } from 'react'
import { Box, LabelStyled, SpanStyled } from '../Styled/Elements';
import { useTranslation } from 'react-i18next';

const FindValue = () => {
    const { t } = useTranslation();
    const [inputValue, setInputValue] = useState(null);
    const [fromUnit, setFromUnit] = useState('Kilogram');
    const [toUnit, setToUnit] = useState('Gram');
    const [outputValue, setOutputValue] = useState(null);
    const [resultValue, setResultValue] = useState(null);

    const units = [
        'Kilogram',
        'Gram',
        'Milligram',
        'MetricTon',
        'LongTon',
        'ShortTon',
        'Pound',
        'Ounce',
        'Carrat',
        'AtomicMassUnit',
    ];



    const convertFromKilogram = (value, toUnit) => {
        switch (toUnit) {
            case 'Gram':
                return value * 1000;
            case 'Kilogram':
                return value;
            case 'Milligram':
                return value * 1e6;
            case 'MetricTon':
                return value / 1000;
            case 'LongTon':
                return value / 1016.04691;
            case 'ShortTon':
                return value / 907.18474;
            case 'Pound':
                return value * 2.20462;
            case 'Ounce':
                return value * 35.274;
            case 'Carat':
                return value * 5000;
            case 'AtomicMassUnit':
                return value / 6.022e26;
            default:
                return ' ';
        }
    };
    const convertFromGram = (value, toUnit) => {
        switch (toUnit) {
            case 'Gram':
                return value;
            case 'Kilogram':
                return value / 1000;
            case 'Milligram':
                return value * 1000;
            case 'MetricTon':
                return value / 1e6;
            case 'LongTon':
                return value / 1.016e6;
            case 'ShortTon':
                return value / 907185;
            case 'Pound':
                return value / 453.592;
            case 'Ounce':
                return value / 28.3495;
            case 'Carat':
                return value / 0.2;
            case 'AtomicMassUnit':
                return value / 6.022e23;
            default:
                return '';
        }
    };
    const convertFromMilliGram = (value, toUnit) => {
        switch (toUnit) {
            case 'Gram':
                return value / 1000;
            case 'Kilogram':
                return value / 1e6;
            case 'Milligram':
                return value;
            case 'MetricTon':
                return value / 1e9;
            case 'LongTon':
                return value / 1.016e9;
            case 'ShortTon':
                return value / 907185000;
            case 'Pound':
                return value / 453592;
            case 'Ounce':
                return value / 28350;
            case 'Carat':
                return value / 200;
            case 'AtomicMassUnit':
                return value / 6.022e23;
            default:
                return '';
        }
    };
    const convertFromMetricTon = (value, toUnit) => {
        switch (toUnit) {
            case 'Gram':
                return value * 1e6;
            case 'Kilogram':
                return value * 1000;
            case 'Milligram':
                return value * 1e9;
            case 'MetricTon':
                return value;
            case 'LongTon':
                return value / 1.016;
            case 'ShortTon':
                return value / 0.907185;
            case 'Pound':
                return value * 2204.62;
            case 'Ounce':
                return value * 35274;
            case 'Carat':
                return value * 5e6;
            case 'AtomicMassUnit':
                return value / 6.022e20;
            default:
                return '';
        }
    };
    const convertFromLongTon = (value, toUnit) => {
        switch (toUnit) {
            case 'Gram':
                return value * 1.016e6;
            case 'Kilogram':
                return value * 1016.04691;
            case 'Milligram':
                return value * 1.016e9;
            case 'MetricTon':
                return value / 1.016;
            case 'LongTon':
                return value;
            case 'ShortTon':
                return value * 1.12;
            case 'Pound':
                return value * 2240;
            case 'Ounce':
                return value * 35840;
            case 'Carat':
                return value * 5e6;
            case 'AtomicMassUnit':
                return value / 6.022e20;
            default:
                return '';
        }
    };
    const convertFromShortTon = (value, toUnit) => {
        switch (toUnit) {
            case 'Gram':
                return value * 907185;
            case 'Kilogram':
                return value * 907.185;
            case 'Milligram':
                return value * 907185000;
            case 'MetricTon':
                return value / 1.102;
            case 'LongTon':
                return value / 1.12;
            case 'ShortTon':
                return value;
            case 'Pound':
                return value * 2000;
            case 'Ounce':
                return value * 32000;
            case 'Carat':
                return value * 453592;
            case 'AtomicMassUnit':
                return value / 5.977e23;
            default:
                return '';
        }
    };
    const convertFromPound = (value, toUnit) => {
        switch (toUnit) {
            case 'Gram':
                return value * 453.592;
            case 'Kilogram':
                return value * 0.453592;
            case 'Milligram':
                return value * 453592;
            case 'MetricTon':
                return value * 0.000453592;
            case 'LongTon':
                return value / 2240;
            case 'ShortTon':
                return value / 2000;
            case 'Pound':
                return value;
            case 'Ounce':
                return value * 16;
            case 'Carat':
                return value * 2267.96;
            case 'AtomicMassUnit':
                return value / 3.953e26;
            default:
                return '';
        }
    };
    const convertFromOunce = (value, toUnit) => {
        switch (toUnit) {
            case 'Gram':
                return value * 28.3495;
            case 'Kilogram':
                return value * 0.0283495;
            case 'Milligram':
                return value * 28349.5;
            case 'MetricTon':
                return value * 2.83495e-5;
            case 'LongTon':
                return value * 2.7902e-5;
            case 'ShortTon':
                return value * 3.125e-5;
            case 'Pound':
                return value / 16;
            case 'Ounce':
                return value;
            case 'Carat':
                return value * 141.748;
            case 'AtomicMassUnit':
                return value / 6.813e25;
            default:
                return '';
        }
    };
    const convertFromCarrat = (value, toUnit) => {
        switch (toUnit) {
            case 'Gram':
                return value * 0.2;
            case 'Kilogram':
                return value * 0.0002;
            case 'Milligram':
                return value * 200;
            case 'MetricTon':
                return value * 2e-7;
            case 'LongTon':
                return value * 1.96841e-7;
            case 'ShortTon':
                return value * 2.20462e-7;
            case 'Pound':
                return value * 0.000440925;
            case 'Ounce':
                return value * 0.007055;
            case 'Carat':
                return value;
            case 'AtomicMassUnit':
                return value / 3.095e23;
            default:
                return '';
        }
    };
    const convertFromAtomic = (value, toUnit) => {
        switch (toUnit) {
            case 'Gram':
                return value * 1.66054e-24;
            case 'Kilogram':
                return value * 1.66054e-27;
            case 'Milligram':
                return value * 1.66054e-21;
            case 'MetricTon':
                return value * 1.66054e-30;
            case 'LongTon':
                return value * 1.63406e-30;
            case 'ShortTon':
                return value * 1.83087e-30;
            case 'Pound':
                return value * 3.66087e-27;
            case 'Ounce':
                return value * 5.85739e-26;
            case 'Carat':
                return value * 2.82343e-23;
            case 'AtomicMassUnit':
                return value;
            default:
                return '';
        }
    };

    const convertUnits = () => {
        const value = parseFloat(inputValue);

        if (isNaN(value)) {
            setOutputValue(null);
            return;
        }

        let result;

        switch (fromUnit) {
            case 'Kilogram':
                result = convertFromKilogram(value, toUnit);
                break;
            case 'Gram':
                result = convertFromGram(value, toUnit);
                break;
            case 'Milligram':
                result = convertFromMilliGram(value, toUnit);
                break;
            case 'MetricTon':
                result = convertFromMetricTon(value, toUnit);
                break;
            case 'LongTon':
                result = convertFromLongTon(value, toUnit);
                break;
            case 'ShortTon':
                result = convertFromShortTon(value, toUnit);
                break;
            case 'Pound':
                result = convertFromPound(value, toUnit);
                break;
            case 'Ounce':
                result = convertFromOunce(value, toUnit);
                break;
            case 'Carrat':
                result = convertFromCarrat(value, toUnit);
                break;
            case 'AtomicMassUnit':
                result = convertFromAtomic(value, toUnit);
                break;
            // Add cases for other units as needed
            default:
                setOutputValue('Invalid unit selected');
                // alert('Invalid unit selected.');
                return;
        }

        setOutputValue(result.toString());
        setResultValue(`${value} ${t(fromUnit)} = ${result.toString()} ${t(toUnit)}`);
    };


    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleFromUnitChange = (e) => {
        setFromUnit(e.target.value);
    };

    const handleToUnitChange = (e) => {
        setToUnit(e.target.value);
    };

    useEffect(() => {
        convertUnits();
    }, [inputValue, fromUnit, toUnit]);

    return (
        <Box className='box-wrapper my-4'>
            <Box className='percentage-field'>
                <Box className='unit-converter'>
                    <LabelStyled>{t('From')}</LabelStyled>
                    <input type="text" value={inputValue} onChange={handleInputChange} />
                    <select value={fromUnit} onChange={handleFromUnitChange}>
                        {units.map((unit) => (
                            <option key={unit} value={unit}>
                                {t(unit)}
                            </option>
                        ))}
                    </select>
                </Box>
                <Box className='button-select'>
                    <button className='btn btn-outline-secondary mt-4' >
                        =
                    </button>
                </Box>
                <Box className='unit-converter'>
                    <LabelStyled>{t('To')}</LabelStyled>
                    <input type="text" value={outputValue} readOnly />
                    <select value={toUnit} onChange={handleToUnitChange}>
                        {units.map((unit) => (
                            <option key={unit} value={unit}>
                                {t(unit)}
                            </option>
                        ))}
                    </select>
                </Box>
            </Box>
            {
                resultValue !== null && (
                    <Box className='mt-4 d-flex align-items-center justify-content-center'>
                        <SpanStyled className='btn btn-danger mx-2'>{t('Result')}: </SpanStyled>
                        <SpanStyled className='btn btn-outline-success mx-2'>{resultValue}</SpanStyled>
                    </Box>
                )
            }


        </Box>

    )
}

export default FindValue